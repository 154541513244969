import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ErrorPage, ErrorPageComponent } from "@cg/error-page";
import { MetaDataService } from "@cg/html-header";

@Component({
  selector: "cg-error",
  templateUrl: "./error.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ErrorPageComponent]
})
export class ErrorComponent implements OnInit {
  public content: ErrorPage = {
    headline: "Die gewünschte Seite wurde nicht gefunden.",
    statusCode: "404"
  };

  public constructor(private metaDataService: MetaDataService) {}

  public ngOnInit() {
    this.metaDataService.setDocumentTitle("Fehler: Seite nicht gefunden");
  }
}
