import type { TextInput } from "@cg/shared";

export const contactInputFields: {
  firstNameInput: TextInput;
  lastNameInput: TextInput;
  emailInput: TextInput;
  mobileInput: TextInput;
} = {
  firstNameInput: {
    id: "contact-firstName",
    controlName: "firstName",
    placeholder: "contactForm.inputs.firstName.placeholder",
    errorMessage: null
  },
  lastNameInput: {
    id: "contact-lastName",
    controlName: "lastName",
    placeholder: "contactForm.inputs.lastName.placeholder",
    placeholderRequired: "contactForm.inputs.lastName.placeholderRequired",
    errorMessage: "contactForm.inputs.lastName.errorMessage"
  },
  emailInput: {
    id: "contact-email",
    controlName: "email",
    placeholder: "contactForm.inputs.email.placeholder",
    placeholderRequired: "contactForm.inputs.email.placeholderRequired",
    errorMessage: "contactForm.inputs.email.errorMessage"
  },
  mobileInput: {
    id: "contact-mobile",
    controlName: "mobile",
    placeholder: "contactForm.inputs.mobile.placeholder",
    placeholderRequired: "contactForm.inputs.mobile.placeholderRequired",
    errorMessage: "contactForm.inputs.mobile.errorMessage"
  }
};
