import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent, HeadlineType } from "@cg/core/ui";
import { CtaComponent, ListComponent } from "@cg/shared";
import type { ListElement } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-list-element",
  templateUrl: "./list-element.component.html",
  styleUrls: ["./list-element.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, ListComponent, CtaComponent]
})
export class ListElementComponent {
  @Input()
  public content: ListElement;

  public readonly headlineType = HeadlineType;
}
