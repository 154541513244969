import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ListElementComponent } from "@cg/list-element";
import type { ContentBlockList } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-content-block-list",
  templateUrl: "./content-block-list.component.html",
  styleUrls: ["./content-block-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, ListElementComponent]
})
export class ContentBlockListComponent {
  @Input()
  public content: ContentBlockList;
}
