import { createAction, props } from "@ngrx/store";
import type { MagnoliaResponse } from "../interfaces/magnolia-response.interface";

export const getContentNode = createAction(
  "[WebContent] Get Content Node",
  props<{
    payload: string;
  }>()
);
export const getContentNodeSuccess = createAction(
  "[WebContent] Get Content Node Success",
  props<{ payload: MagnoliaResponse }>()
);
export const getContentNodeFailure = createAction("[WebContent] Get Content Node Failure", props<{ error: string }>());
export const setCurrentUrl = createAction("[WebContent] Set Current URL", props<{ payload: string }>());
