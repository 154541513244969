<div class="error-page-wrapper">
  <div class="text-content">
    <cg-headline
      class="headline"
      data-cy="headline"
      [type]="headlineType.h1"
      [content]="content.headline"
    ></cg-headline>
    <cg-headline
      class="status-code"
      data-cy="status-code"
      [type]="headlineType.h1"
      [content]="content.statusCode"
    ></cg-headline>
  </div>
</div>
