import { HttpErrorResponse } from "@angular/common/http";
import { APP_INITIALIZER } from "@angular/core";
import { combineLatest, firstValueFrom, of, take, tap } from "rxjs";
import { catchError, filter, mergeMap, timeout } from "rxjs/operators";
import { environment } from "../environments/src";
import { FeatureToggleFacade, Feature } from "../feature-toggle/src";
import { OLB_CONFIG, OlbConfiguration } from "../olb/configuration/src";
import { ConfigFacade } from "../shared/src";
import { SecurityFacade } from "./api/src";
import { IS_BROWSER_PLATFORM } from "./utils/src";

export function provideAppInitializer() {
  const credentials = { name: "user", password: "password" };

  return {
    provide: APP_INITIALIZER,
    useFactory:
      (
        featureToggleFacade: FeatureToggleFacade,
        securityFacade: SecurityFacade,
        olbConfiguration: OlbConfiguration,
        configFacade: ConfigFacade,
        isBrowser: boolean
      ) =>
      async () => {
        if (!isBrowser) {
          return;
        }

        securityFacade.loginWithCredentials(credentials);

        firstValueFrom(
          securityFacade.accessToken$.pipe(
            filter((accessToken: string) => !!accessToken),
            take(1),
            tap(() => {
              configFacade.getConfigFor(olbConfiguration.externalConfiguration?.groupNames);
            })
          )
        );

        return firstValueFrom(
          securityFacade.accessToken$.pipe(
            filter((accessToken: string) => !!accessToken),
            take(1),
            tap(() => {
              featureToggleFacade.init(environment.brand);
            }),
            mergeMap(() => featureToggleFacade.features$),
            timeout(5000),
            filter((features: Feature[]) => !!features),
            catchError((error: HttpErrorResponse) => {
              console.warn("Request time out of feature toggle " + error.message);
              return of(error);
            })
          )
        );
      },
    multi: true,
    deps: [FeatureToggleFacade, SecurityFacade, OLB_CONFIG, ConfigFacade, IS_BROWSER_PLATFORM]
  };
}
