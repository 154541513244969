import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { OptimizelyService, VariationMap } from "@cg/analytics";
import { ApiService } from "@cg/core/api";
import { EnvironmentConfiguration } from "@cg/core/interfaces";
import { environment } from "@cg/environments";
import { MagnoliaResponse } from "../interfaces/magnolia-response.interface";

@Injectable({
  providedIn: "root"
})
export class WebContentService {
  private contentCache = new Map<string, MagnoliaResponse>();
  public config: EnvironmentConfiguration = environment;

  public constructor(
    private apiService: ApiService,
    protected optimizelyService: OptimizelyService
  ) {}

  public getContentNodeFromService(path: string, variationMap: VariationMap): Observable<MagnoliaResponse> {
    path += this.getVariantQueryString(variationMap);

    if (this.contentCache.has(path)) {
      return of(this.contentCache.get(path));
    }

    const additionalProps: Partial<MagnoliaResponse> = {
      timestamp: Date.now()
    };

    return this.apiService.get("webcontent", path, { disableRetry: true }).pipe(
      map((response: MagnoliaResponse) => {
        response = {
          ...response,
          ...additionalProps
        };

        this.contentCache.set(path, response);

        return response;
      })
    );
  }

  private getVariantQueryString(variationMap: VariationMap): string {
    let queryParams = "";
    for (const experimentId in variationMap) {
      if (experimentId in variationMap) {
        queryParams += queryParams.length === 0 ? "?" : "&";
        queryParams += "variant=" + experimentId + "-" + variationMap[experimentId].id;
      }
    }

    return queryParams;
  }
}
