<div
  class="cms-host-container-wrapper"
  [ngClass]="{
    'mb-[80px] t:mb-[120px]': content?.whitespace === 'l',
    'mb-16 t:mb-20': content?.whitespace === 'm',
    'mb-12': content?.whitespace === 's'
  }"
>
  <ng-template #host></ng-template>
</div>
