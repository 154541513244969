import { inject } from "@angular/core";
import { first, mergeMap } from "rxjs/operators";
import { ApiService } from "@cg/core/api";
import { IS_SERVER_PLATFORM } from "@cg/core/utils";
import type { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { TestbotFacade } from "../+state/testbot.facade";

/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO: type?
function isApiRequest(request: HttpRequest<any>, apiService: ApiService): boolean {
  return request.url.indexOf(apiService.getHost()) >= 0;
}

/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO: type?
function addTestbotHeader(request: HttpRequest<any>, identifier: string): HttpRequest<any> {
  return request.clone({ setHeaders: { "X-CG-Testbot": identifier } });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const testbotInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
  const isServer = inject(IS_SERVER_PLATFORM);
  const testbotFacade = inject(TestbotFacade);
  const apiService = inject(ApiService);

  if (isServer) {
    return next(req);
  }

  return testbotFacade.identifier$.pipe(
    first(),
    mergeMap((identifier: string) => {
      if (!isApiRequest(req, apiService) || !identifier) {
        return next(req);
      }

      return next(addTestbotHeader(req, identifier));
    })
  );
};
