import { registerLocaleData, ViewportScroller } from "@angular/common";
import * as de from "@angular/common/locales/de";
import { afterNextRender, ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { AnalyticsEventContainerComponent } from "@cg/analytics";
import { UserCentricsService } from "@cg/consent-management";
import { MetaDataService } from "@cg/html-header";

@Component({
  selector: "cg-root",
  templateUrl: "./app.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, AnalyticsEventContainerComponent]
})
export class AppComponent implements OnInit {
  public constructor(
    private readonly userCentricsService: UserCentricsService,
    private readonly metaDataService: MetaDataService,
    private readonly viewPortScroller: ViewportScroller
  ) {
    afterNextRender(() => {
      this.userCentricsService.initialize();
    });
  }

  public ngOnInit() {
    registerLocaleData(de.default);
    this.viewPortScroller.setOffset([0, 80]);
    this.metaDataService.syncAlternateLinkWithNavigation();
  }
}
