import type { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

export class ContentRouteReuseStrategy implements RouteReuseStrategy {
  public retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  public shouldAttach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public shouldDetach(_route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (
      curr.routeConfig !== null &&
      "path" in curr.routeConfig &&
      curr.routeConfig.path === "**" &&
      "path" in future.routeConfig &&
      future.routeConfig.path === "**"
    ) {
      return false;
    }

    return future.routeConfig === curr.routeConfig;
  }

  public store(_route: ActivatedRouteSnapshot, _handle: DetachedRouteHandle | null): void {}
}
