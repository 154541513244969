<div class="teaser-services-map-container">
  <div class="teaser-services-map-text-box" data-cy="teaser-services-map-text-box">
    <p class="teaser-services-map-text-box-counter" data-cy="teaser-services-map-text-box-counter">
      {{ content.counter }}
    </p>
    <p class="teaser-services-map-text-box-description" data-cy="teaser-services-map-text-box-description">
      {{ content.description }}
    </p>
  </div>
  <cg-icon [content]="content.icon"></cg-icon>
</div>
