<div
  [ngClass]="{
    'mx-auto text-center': content?.alignment === alignment.CENTER
  }"
  class="max-w-[888px]"
>
  <cg-headline [type]="headlineType.h1" [content]="content.headline"></cg-headline>
  <cg-richtext [content]="content.paragraph"></cg-richtext>
  <div class="flex flex-col justify-center gap-4 t:flex-row">
    @if (content.ctaLast) {
      <cg-cta [content]="content.ctaLast"></cg-cta>
    }
    @if (content.ctaFirst) {
      <cg-cta [content]="content.ctaFirst"></cg-cta>
    }
  </div>
</div>
