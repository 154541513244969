import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent } from "@cg/core/ui";
import type { TeaserColumn2X } from "@cg/content-api/typescript-interfaces";
import { TeaserCardComponent } from "../teaser-card/teaser-card.component";

@Component({
  selector: "cg-teaser-two-column",
  templateUrl: "./teaser-two-column.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, TeaserCardComponent]
})
export class TeaserTwoColumnComponent {
  @Input()
  public content: TeaserColumn2X;
}
