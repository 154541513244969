import { createAction, props } from "@ngrx/store";
import type { ContactEmailInterface } from "../models/contactEmail.interface";

export const sendContactEmail = createAction(
  "[ContactForm] Send Contact E-Mail",
  props<{ payload: ContactEmailInterface }>()
);

export const sendContactEmailSuccess = createAction("[ContactForm] Send Contact E-Mail Success");

export const sendContactEmailFailure = createAction(
  "[ContactForm] Send Contact E-Mail Failure",
  props<{ error: string }>()
);
