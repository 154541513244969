import { provideEffects } from "@ngrx/effects";
import { provideState } from "@ngrx/store";
import { ContactFormEffects } from "../+state/contact-form.effects";
import { ContactFormFacade } from "../+state/contact-form.facade";
import * as fromContactForm from "../+state/contact-form.reducer";

export function provideContactForm() {
  return [
    provideState(fromContactForm.CONTACT_FORM_FEATURE_KEY, fromContactForm.contactFormReducer),
    provideEffects(ContactFormEffects),
    ContactFormFacade
  ];
}
