import { AsyncPipe } from "@angular/common";
import { Component, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { InsuranceFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptionSelectionOverlaySearchComponent } from "@cg/olb/shared";
import { DrawerComponent, OptionSelectionItem, OverlayService } from "@cg/shared";

@Component({
  selector: "lib-insurance-overlay",
  standalone: true,
  imports: [DrawerComponent, OptionSelectionOverlaySearchComponent, AsyncPipe, TranslocoPipe],
  templateUrl: "./insurance-overlay.component.html"
})
export class InsuranceOverlayComponent implements OnInit {
  public insurances$!: Observable<OptionSelectionItem[]>;

  @Output()
  public itemSelected!: (item: OptionSelectionItem) => void;

  public constructor(
    private readonly insuranceFacade: InsuranceFacade,
    private readonly overlayService: OverlayService
  ) {}

  public ngOnInit(): void {
    this.insurances$ = this.insuranceFacade.createInsurancesOptionsWithTopInsurances();
    this.insuranceFacade.loadInsurances();
  }

  public close = (item?: OptionSelectionItem): void => {
    if (item) {
      this.itemSelected(item);
    }
    this.overlayService.close();
  };
}
