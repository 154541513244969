import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent, HeadlineType, RichtextComponent } from "@cg/core/ui";
import { CtaComponent } from "@cg/shared";
import type { ContentBlockIntro } from "@cg/content-api/typescript-interfaces";
import { Alignment } from "../../enums/alignment.enum";

@Component({
  selector: "cg-content-block-intro",
  templateUrl: "./content-block-intro.component.html",
  styleUrls: ["./content-block-intro.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, HeadlineComponent, RichtextComponent, CtaComponent]
})
export class ContentBlockIntroComponent {
  public readonly alignment = Alignment;
  public readonly headlineType = HeadlineType;

  @Input()
  public content: ContentBlockIntro;
}
