import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as TestbotActions from "./testbot.actions";
import { TestbotPartialState } from "./testbot.reducer";
import * as TestbotSelectors from "./testbot.selectors";

@Injectable({
  providedIn: "root"
})
export class TestbotFacade {
  public identifier$ = this.store.pipe(select(TestbotSelectors.getTestbotIdentifier));

  public constructor(private store: Store<TestbotPartialState>) {}

  public setTestbotHeader(identifier: string) {
    this.store.dispatch(TestbotActions.setTestbotHeader({ identifier }));
  }
}
