<div class="mx-auto max-w-container t:px-2 w:px-6 fhd:px-0">
  <div class="flex flex-col justify-between gap-6 fhd:flex-row">
    <div class="flex grow flex-col">
      <div>
        <p
          class="mb-4 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite fhd:text-xl fhd:leading-6"
        >
          {{ "costCheck.insurance.type" | transloco }}
        </p>
        <cg-radio-button-group
          class="fhd:[&>div>div]:w-1/2 fhd:[&>div]:flex-row fhd:[&>div]:gap-2"
          [content]="insuranceOptions"
          (selectedValueChanged)="onSelectedValueChanged($event)"
        ></cg-radio-button-group>
        @if (isKasko) {
          <p
            class="mb-4 pt-6 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite fhd:x-[pt-12,text-xl,leading-6]"
          >
            {{ "costCheck.insurance.name" | transloco }}
          </p>
          <cg-fake-dropdown
            [placeholder]="selectedInsurance ? selectedInsurance.text : ('costCheck.insurance.placeholder' | transloco)"
            [hasSelection]="selectedInsurance !== undefined"
            (press)="onInsuranceSelectionClicked()"
            (click)="onInsuranceSelectionClicked()"
          ></cg-fake-dropdown>
          @if (selectedInsurance) {
            <div class="mb-4 py-6">
              <div
                class="mb-2 flex flex-row gap-4 rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-green p-4"
              >
                <cg-icon class="h-5 w-5" [content]="confirmedIcon"></cg-icon>
                <p class="font-roboto-condensed text-[17px] font-bold leading-5 text-white">
                  {{ "costCheck.withInsurance.title" | transloco }}
                </p>
              </div>
              <div
                class="mb-2 flex flex-row justify-between rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-gray-light-80 p-4"
              >
                <div class="flex flex-col">
                  <p
                    class="mb-3 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite"
                    [innerHTML]="'costCheck.withInsurance.repair.title' | transloco"
                  ></p>
                  <p class="mb-1 font-roboto-condensed text-[13px] text-sm text-cg-anthracite">
                    {{ "costCheck.withInsurance.repair.description" | transloco }}
                  </p>
                </div>
                <div class="flex min-w-[75px] flex-col items-end">
                  <div class="font-roboto-condensed text-[26px] font-bold leading-7 text-cg-green">0 €</div>
                </div>
              </div>
              <div
                class="flex flex-row justify-between rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-gray-light-80 p-4"
              >
                <div class="flex flex-col">
                  <p
                    class="mb-3 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite"
                    [innerHTML]="'costCheck.withInsurance.replace.title' | transloco"
                  ></p>
                  <p class="mb-3 font-roboto-condensed text-[13px] text-sm text-cg-anthracite">
                    {{ "costCheck.withInsurance.replace.subtitle" | transloco }}
                  </p>
                  <p class="mb-1 font-roboto-condensed text-[13px] text-sm text-cg-anthracite">
                    {{ "costCheck.withInsurance.replace.description" | transloco }}
                  </p>
                </div>
                <div class="flex min-w-[75px] flex-col items-end">
                  <div class="mb-2 font-roboto-condensed text-[26px] font-bold leading-7 text-cg-green">0 €</div>
                  <p class="font-roboto-condensed text-[13px] text-sm text-cg-anthracite">
                    {{ "costCheck.withInsurance.replace.price" | transloco }}
                  </p>
                </div>
              </div>
            </div>
          }
        } @else if (isKasko === false) {
          <div class="mb-4 py-6">
            <div class="mb-2 rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-gray-light-80 p-4">
              <p class="mb-1 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite">
                {{ "costCheck.repair" | transloco }}
              </p>
              <ul class="type-body-1 list-none space-y-2 pl-0">
                @for (item of repairList; track item) {
                  <li class="flex items-center gap-3">
                    <cg-icon class="h-5 w-5 t:h-6 t:w-6" [content]="bulletpointIcon"></cg-icon>
                    <span class="text-cg-anthracite" [innerHTML]="item.key | transloco: { price: item.value }"></span>
                  </li>
                }
              </ul>
            </div>
            <div class="rounded-card border-[1px] border-cg-anthracite-light-80 bg-cg-gray-light-80 p-4">
              <p class="mb-1 font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite">
                {{ "costCheck.replace" | transloco }}
              </p>
              <ul class="type-body-1 list-none space-y-2 pl-0">
                @for (item of replaceList; track item) {
                  <li class="flex items-center gap-3">
                    <cg-icon class="h-5 w-5 t:h-6 t:w-6" [content]="bulletpointIcon"></cg-icon>
                    <span class="text-cg-anthracite" [innerHTML]="item.key | transloco: { price: item.value }"></span>
                  </li>
                }
              </ul>
              <p class="pt-2 text-[13px] leading-5">{{ "costCheck.withoutInsurance.note" | transloco }}</p>
            </div>
          </div>
        }
      </div>
      @if (isKasko === false || (isKasko && selectedInsurance)) {
        <div class="flex flex-col items-center justify-between rounded-card bg-cg-gray-light-70 p-6 fhd:flex-row">
          <div class="mb-2">
            <p
              class="mb-1 text-center font-roboto-condensed text-[17px] font-bold leading-5 text-cg-anthracite fhd:x-[text-left,text-xl,leading-6]"
            >
              {{ "costCheck.cta.title" | transloco }}
            </p>
            <p class="mb-1 text-center text-base text-cg-anthracite-light-30 fhd:text-left">
              {{ "costCheck.cta.subtitle" | transloco }}
            </p>
          </div>
          <cg-cta [content]="cta" [translateContent]="true"></cg-cta>
        </div>
      }
    </div>

    <div class="rounded-card bg-cg-gray-light-70 fhd:bg-cg-white">
      <div class="px-4 pb-6 pt-12 fhd:pt-0">
        @if (isUntilFullHD) {
          <cg-headline
            class="part-[headline]:mb-6 part-[headline]:text-center"
            [type]="headlineType.h3"
            [content]="'costCheck.customerRating.title' | transloco"
          ></cg-headline>
        }
        <mat-card class="mx-auto max-w-[363px] !rounded-card">
          <mat-card-header class="h-12 items-center rounded-t-card bg-cg-anthracite">
            @if (consent) {
              <div
                class="trustpilot-widget pb-4"
                data-locale="de-DE"
                data-template-id="5419b637fa0340045cd0c936"
                data-businessunit-id="554720c10000ff00057f2b9b"
                data-style-height="20px"
                data-style-width="100%"
                data-theme="dark"
              >
                <a
                  class="text-cg-white"
                  href="https://de.trustpilot.com/review/carglass.de"
                  target="_blank"
                  rel="noopener"
                  >{{ "costCheck.customerRating.placeholder" | transloco }}</a
                >
              </div>
            }
          </mat-card-header>
          <mat-card-content class="rounded-b-card bg-cg-white !p-4 fhd:bg-cg-gray-light-70">
            <div class="mb-4 flex flex-row justify-between gap-2">
              <div class="flex flex-col items-center">
                <cg-icon class="h-10 w-10" [content]="noPaymentCheckedIcon"></cg-icon>
                <p class="pt-1 text-center font-roboto-condensed text-[13px] leading-4">
                  {{ "costCheck.usp.cost" | transloco }}
                </p>
              </div>
              <div class="flex flex-col items-center">
                <cg-icon class="h-10 w-10" [content]="timeCheckedIcon"></cg-icon>
                <p class="pt-1 text-center font-roboto-condensed text-[13px] leading-4">
                  {{ "costCheck.usp.time" | transloco }}
                </p>
              </div>
              <div class="flex flex-col items-center">
                <cg-icon class="h-10 w-10" [content]="windscreenAvailableIcon"></cg-icon>
                <p class="pt-1 text-center font-roboto-condensed text-[13px] leading-4">
                  {{ "costCheck.usp.guarantee" | transloco }}
                </p>
              </div>
            </div>
            <div class="flex flex-row gap-4 rounded-card bg-cg-gray-light-70 p-3 fhd:bg-cg-white">
              <cg-icon
                class="h-14 w-14"
                [ngClass]="{ 'part-[icon|>svg>path:nth-of-type(1)]:fill-white': !isUntilFullHD }"
                [content]="insuranceIcon"
              ></cg-icon>
              <p
                class="pt-3 text-[13px] leading-4 text-cg-anthracite"
                [innerHTML]="'costCheck.usp.insurance' | transloco"
              ></p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="mx-auto flex max-w-[363px] flex-col items-center gap-6 px-4 pb-[72px] pt-6">
        <cg-headline
          class="part-[headline]:text-center"
          [type]="headlineType.h3"
          [content]="'costCheck.customerRating.subtitle' | transloco"
        ></cg-headline>
        <cg-picture class="max-w-[280px]" [content]="tuevPicture" [shouldTranslateAlt]="true"></cg-picture>
      </div>
    </div>
  </div>
</div>
