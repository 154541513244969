<div class="w-full max-w-container">
  <div class="mx-auto d:mx-0">
    @if (content?.headline) {
      <cg-headline [type]="headlineType.h2" [content]="content?.headline"></cg-headline>
    }
    <div
      class="column-1 gap-12 d:columns-2"
      data-cy="column-wrapper"
      [ngClass]="getColumnNumberClass()"
      [attr.data-cy]="getColumnNumberClass()"
    >
      <cg-richtext [content]="content.paragraph"></cg-richtext>
    </div>
  </div>
</div>
