import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import flatten from "lodash/flatten";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";

@Injectable({
  providedIn: "root"
})
export class JsonLdService {
  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean
  ) {}

  private static mergeJsonLd(existingJson: string, newJson: string): string {
    const parsedExistingJson = JSON.parse(existingJson);
    const parsedNewJson = JSON.parse(newJson);
    const merged = flatten([parsedExistingJson, parsedNewJson]);

    return JSON.stringify(merged);
  }

  public removeJsonLdScriptTag(): void {
    if (this.isBrowser) {
      const jsonLdElement = this.document.querySelector("script[id='jsonld']");

      if (jsonLdElement) {
        this.document.head.removeChild(jsonLdElement);
      }
    }
  }

  public updateJsonLdScriptTag(jsonLd: string) {
    if (!this.isBrowser) {
      return;
    }
    const pageJsonLdScript = this.document.querySelector("script[id='jsonld']");

    if (!pageJsonLdScript) {
      this.addJsonLdScriptTag(jsonLd);
      return;
    }

    try {
      pageJsonLdScript.textContent = JsonLdService.mergeJsonLd(pageJsonLdScript.textContent, jsonLd);
    } catch (e) {
      // JSON-LD is provided in magnolia but not validated
      // that maybe leads to multiple errors which exceeds the sentry quota.
      // avoid error logging in sentry

      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  public addJsonLdScriptTag(jsonLd: string) {
    if (!this.isBrowser) {
      return;
    }
    try {
      const jsonLdJson = JSON.parse(jsonLd);
      const textContent = Array.isArray(jsonLdJson) ? jsonLdJson : [jsonLdJson];

      const pageJsonLdScript = this.document.createElement("script");
      pageJsonLdScript.type = "application/ld+json";
      pageJsonLdScript.id = "jsonld";
      pageJsonLdScript.textContent = JSON.stringify(textContent);

      this.document.head.insertBefore(pageJsonLdScript, this.document.head.firstChild);
    } catch (e) {
      // JSON-LD is provided in magnolia but not validated
      // that maybe leads to multiple errors which exceeds the sentry quota.
      // avoid error logging in sentry

      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
}
