<div class="teaser-services-container">
  <div class="teaser-services-left-column">
    <cg-teaser-services-map [content]="content.serviceMap"></cg-teaser-services-map>
    <div class="teaser-services-left-column-content">
      <div>
        <cg-headline
          class="teaser-services-left-column-content-headline"
          data-cy="teaser-services-left-column-content-headline"
          [type]="content.leftColumn.headline.type"
          [content]="content.leftColumn.headline.value"
        ></cg-headline>
        <p
          class="teaser-services-left-column-content-paragraph"
          data-cy="teaser-services-left-column-content-paragraph"
        >
          {{ content.leftColumn.paragraph }}
        </p>
        <cg-cta [content]="content.leftColumn.cta"></cg-cta>
      </div>
    </div>
  </div>
  <cg-ccc-phone-info
    class="mb-4 ml-4 mr-4 h-full min-h-full max-w-full d:ml-0 d:mr-0 d:max-w-[472px] w:mb-0 w:min-h-[173px]"
  ></cg-ccc-phone-info>
</div>
