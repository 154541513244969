import type { OptionSelectionItem } from "@cg/shared";
import { SubjectType } from "../enums/subjectType.enum";

export const subjectOptions: OptionSelectionItem[] = [
  {
    id: SubjectType.MOVE_APPOINTMENT,
    value: SubjectType.MOVE_APPOINTMENT,
    text: "contactForm.subjectType.move-appointment",
    disabled: false
  },
  {
    id: SubjectType.CANCEL_APPOINTMENT,
    value: SubjectType.CANCEL_APPOINTMENT,
    text: "contactForm.subjectType.cancel-appointment",
    disabled: false
  },
  {
    id: SubjectType.REPAIR_REPLACE,
    value: SubjectType.REPAIR_REPLACE,
    text: "contactForm.subjectType.repair-replace",
    disabled: false
  },
  { id: SubjectType.GENERAL, value: SubjectType.GENERAL, text: "contactForm.subjectType.general", disabled: false },
  {
    id: SubjectType.COMPLAINT_FEEDBACK,
    value: SubjectType.COMPLAINT_FEEDBACK,
    text: "contactForm.subjectType.complaint-feedback",
    disabled: false
  },
  { id: SubjectType.VAPS, value: SubjectType.VAPS, text: "contactForm.subjectType.vaps", disabled: false },
  {
    id: SubjectType.TECHNICAL_PROBLEMS,
    value: SubjectType.TECHNICAL_PROBLEMS,
    text: "contactForm.subjectType.technical-problems",
    disabled: false
  },
  {
    id: SubjectType.GUARANTEE_WARRANTY,
    value: SubjectType.GUARANTEE_WARRANTY,
    text: "contactForm.subjectType.guarantee-warranty",
    disabled: false
  },
  {
    id: SubjectType.BILL_PAYMENT,
    value: SubjectType.BILL_PAYMENT,
    text: "contactForm.subjectType.bill-payment",
    disabled: false
  },
  {
    id: SubjectType.CALLBACK,
    value: SubjectType.CALLBACK,
    text: "contactForm.subjectType.call-back",
    disabled: false
  },
  {
    id: SubjectType.OTHERS,
    value: SubjectType.OTHERS,
    text: "contactForm.subjectType.others",
    disabled: false
  }
];
