import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { HreflangLanguage, MetaDataService } from "@cg/html-header";
import { JsonLdService } from "@cg/json-ld";
import { MagnoliaResponse } from "../../interfaces/magnolia-response.interface";
import { CmsHostContainerComponent } from "../cms-host-container/cms-host-container.component";

@Component({
  selector: "cg-cms-render-container",
  templateUrl: "./cms-render-container.component.html",
  styleUrls: ["./cms-render-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CmsHostContainerComponent]
})
export class CmsRenderContainerComponent implements OnChanges {
  @Input() public content: MagnoliaResponse | null;

  public constructor(
    private metaDataService: MetaDataService,
    private jsonLdService: JsonLdService
  ) {}

  public isPage(): boolean {
    const validPages = ["contentpage", "homepage"];

    if (typeof this.content !== "undefined") {
      const contentType = this.content["ngTemplate"];
      return validPages.includes(contentType);
    }

    return false;
  }

  public ngOnChanges(): void {
    this.updateMetaData();
    this.updateHreflangTags();
    this.updateJsonLdData();
  }

  public updateMetaData(): void {
    if (!this.isPage() || !this.content) {
      return;
    }

    const { title, description, keywords, robots } = this.content;

    this.metaDataService.setDocumentTitle(title);
    this.metaDataService.addOrUpdateTag("description", description);
    this.metaDataService.addOrUpdateTag("keywords", keywords);
    this.metaDataService.addOrUpdateTag("robots", robots);
  }

  public updateHreflangTags(): void {
    if (!this.isPage() || !this.content) {
      return;
    }

    const { hreflangat, hreflangch } = this.content;

    this.metaDataService.setHreflangLink(hreflangat, HreflangLanguage.GERMAN_AUSTRIA);
    this.metaDataService.setHreflangLink(hreflangch, HreflangLanguage.GERMAN_SWITZERLAND);
  }

  public updateJsonLdData(): void {
    this.jsonLdService.removeJsonLdScriptTag();
    this.setPageJsonLdData();
    this.setBreadcrumbJsonLdData();
  }

  public setPageJsonLdData(): void {
    const { jsonLd } = this.content;

    if (jsonLd) {
      this.jsonLdService.updateJsonLdScriptTag(jsonLd);
    }
  }

  public setBreadcrumbJsonLdData(): void {
    const breadcrumb = this.content?.breadcrumb;

    if (breadcrumb?.jsonLd) {
      this.jsonLdService.updateJsonLdScriptTag(breadcrumb.jsonLd);
    }
  }
}
