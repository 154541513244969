import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent, HeadlineType } from "@cg/core/ui";
import type { Distributor } from "@cg/content-api/typescript-interfaces";
import { DistributorCardComponent } from "../distributor-card/distributor-card.component";

@Component({
  selector: "cg-distributor",
  templateUrl: "./distributor.component.html",
  styleUrls: ["./distributor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, DistributorCardComponent]
})
export class DistributorComponent {
  @Input()
  public content: Distributor;

  public readonly headlineType = HeadlineType;
}
