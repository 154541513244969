import { ErrorHandler, type Provider } from "@angular/core";
import type { SentryConfiguration } from "./interfaces/sentry-configutation.interface";
import { SentryErrorHandlerService } from "./services/sentry-error-handler.service";
import { SENTRY_CONFIGURATION } from "./token/sentry-configuration.token";

export function provideSentry(configuration: SentryConfiguration): Provider[] {
  return [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService
    },
    { provide: SENTRY_CONFIGURATION, useValue: configuration }
  ];
}
