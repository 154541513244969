import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent, PictureComponent } from "@cg/core/ui";
import { CtaComponent } from "@cg/shared";
import type { TeaserCard } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-teaser-card",
  templateUrl: "./teaser-card.component.html",
  styleUrls: ["./teaser-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, CtaComponent, PictureComponent]
})
export class TeaserCardComponent {
  @Input()
  public content: TeaserCard;
}
