import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent, HeadlineType, IconComponent, RichtextComponent } from "@cg/core/ui";
import { CccPhoneInfoComponent, CtaComponent, ListComponent } from "@cg/shared";
import type { ContentBlockContact } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-content-block-contact",
  templateUrl: "./content-block-contact.component.html",
  styleUrls: ["./content-block-contact.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, HeadlineComponent, RichtextComponent, ListComponent, CtaComponent, CccPhoneInfoComponent]
})
export class ContentBlockContactComponent {
  @Input()
  public content: ContentBlockContact;

  public readonly headlineType = HeadlineType;
}
