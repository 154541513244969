import { HeadlineType } from "@cg/core/ui";
import { arrowsIcon, phoneIcon, serviceMapIcon } from "@cg/icon";
import { FormatTelLinkPipe, PhoneNumber } from "@cg/shared";
import type { TeaserServices } from "../interfaces/teaser-services.interface";

const standortAuswaehlen = "Standort auswählen";
export const teaserServicesModel: TeaserServices = {
  serviceMap: {
    counter: "370",
    description: "Service-Center in Deutschland",
    icon: serviceMapIcon
  },
  leftColumn: {
    headline: { type: HeadlineType.h2, value: "Wir sind immer in Ihrer Nähe" },
    cta: {
      id: "teaser-services-standorte-auswaehlen",
      ngTemplate: "cgCta",
      whitespace: "s",
      variation: "primary",
      arrowDirection: "right",
      icon: arrowsIcon,
      title: standortAuswaehlen,
      link: {
        title: standortAuswaehlen,
        href: "https://www.carglass.de/standorte",
        target: "_blank",
        routerLink: false,
        text: standortAuswaehlen
      }
    },
    paragraph: "Sie finden uns in über 370 Service-Centern in ganz Deutschland."
  },
  rightColumn: {
    icon: phoneIcon,
    headline: {
      value: `Für Sie sind wir erreichbar: <a href='${FormatTelLinkPipe.getPhoneLink(PhoneNumber.DEFAULT)}'>${PhoneNumber.DEFAULT}</a>`,
      type: HeadlineType.h4
    },
    paragraph:
      "Kostenlos - Montag bis Freitag: 07:00 bis 20:00 Uhr, Samstag 09:00 bis 18:00 Uhr und Sonntag 10:00 bis 18:00 Uhr."
  }
};
