import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CONTACT_FORM_FEATURE_KEY, type ContactFormState } from "./contact-form.reducer";

export const selectContactFormState = createFeatureSelector<ContactFormState>(CONTACT_FORM_FEATURE_KEY);

export const selectContactFormLoaded = createSelector(
  selectContactFormState,
  (state: ContactFormState) => state.loaded
);

export const selectContactFormError = createSelector(selectContactFormState, (state: ContactFormState) => state.error);
