import { type Action, createReducer, on } from "@ngrx/store";
import * as TestbotActions from "./testbot.actions";

export const TESTBOT_FEATURE_KEY = "testbot";

export interface State {
  identifier: string;
}

export interface TestbotPartialState {
  readonly [TESTBOT_FEATURE_KEY]: State;
}

export const initialState: State = {
  identifier: null
};

const testbotReducer = createReducer(
  initialState,
  on(TestbotActions.setTestbotHeader, (state: State, { identifier }: { identifier: string }) => ({
    ...state,
    identifier
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return testbotReducer(state, action);
}
