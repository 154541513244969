import { environment } from "@cg/environments";
import type { Trust } from "../interfaces/trust.interface";

export const trustModel: Trust = {
  picture: {
    sizes: [],
    source: `${environment.assetPath}/icons/tuev.webp`,
    alt: "trustModel.alt",
    ngTemplate: "cgPicture"
  },
  headline: "trustModel.headline",
  paragraph: {
    text: "trustModel.description",
    ngTemplate: "cgParagraph"
  },
  list: {
    items: ["trustModel.list.1", "trustModel.list.2", "trustModel.list.3"],
    type: "check",
    ngTemplate: "cgList"
  }
};
