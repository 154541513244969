import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { YoutubeComponent } from "@cg/youtube";
import { ContentBlockImgComponent, HeadlineComponent, RichtextComponent } from "@cg/core/ui";
import { JsonLdService } from "@cg/json-ld";
import { CtaComponent, ListComponent } from "@cg/shared";
import type {
  ContentBlockImageVideoHorizontal,
  ContentBlockImageVideoHorizontalItem
} from "@cg/content-api/typescript-interfaces";
import { MediaType } from "../../enums/mediaType.enum";

@Component({
  selector: "cg-content-block-image-video-horizontal",
  templateUrl: "./content-block-image-video-horizontal.component.html",
  styleUrls: ["./content-block-image-video-horizontal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    ContentBlockImgComponent,
    HeadlineComponent,
    RichtextComponent,
    ListComponent,
    CtaComponent,
    YoutubeComponent
  ]
})
export class ContentBlockImageVideoHorizontalComponent {
  private _content: ContentBlockImageVideoHorizontal;

  @Input()
  public set content(value: ContentBlockImageVideoHorizontal) {
    this._content = value;
    const jsonLdItems = value.items.filter((item: ContentBlockImageVideoHorizontalItem) => !!item.jsonLd);

    jsonLdItems.forEach((item: ContentBlockImageVideoHorizontalItem) =>
      this.jsonLdService.updateJsonLdScriptTag(item.jsonLd)
    );
  }

  public get content(): ContentBlockImageVideoHorizontal {
    return this._content;
  }

  public mediaType = MediaType;

  public constructor(private jsonLdService: JsonLdService) {}
}
