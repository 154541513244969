import { createFeatureSelector, createSelector } from "@ngrx/store";
import { WEBCONTENT_FEATURE_KEY, type WebContentState } from "./web-content.reducer";

export const getWebContentState = createFeatureSelector<WebContentState>(WEBCONTENT_FEATURE_KEY);

export const hasLoaded = createSelector(getWebContentState, (state: WebContentState) => state.loaded);

export const getError = createSelector(getWebContentState, (state: WebContentState) => state.error);

export const getCurrentUrl = createSelector(getWebContentState, (state: WebContentState) => state.currentUrl);

export const getCurrentContent = createSelector(getWebContentState, (state: WebContentState) =>
  state.error ? null : state.currentContent
);
