import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconComponent } from "@cg/core/ui";
import { TeaserServicesMap } from "../../interfaces/teaser-services-map.interface";

@Component({
  selector: "cg-teaser-services-map",
  templateUrl: "./teaser-services-map.component.html",
  styleUrls: ["./teaser-services-map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent]
})
export class TeaserServicesMapComponent {
  @Input()
  public content: TeaserServicesMap;
}
