import { RouteReuseStrategy } from "@angular/router";
import { provideEffects } from "@ngrx/effects";
import { provideState } from "@ngrx/store";
import { WebContentEffects } from "./+state/web-content.effects";
import * as fromWebContent from "./+state/web-content.reducer";
import { ContentRouteReuseStrategy } from "./strategies/content-route-reuse.strategy";

export function provideCms() {
  return [
    provideState(fromWebContent.WEBCONTENT_FEATURE_KEY, fromWebContent.reducer),
    provideEffects([WebContentEffects]),
    {
      provide: RouteReuseStrategy,
      useClass: ContentRouteReuseStrategy
    }
  ];
}
