<cg-drawer
  [content]="content"
  (closedClicked)="close()"
  class="max-h-fill-available !bottom-auto top-0 h-screen [&>div]:x-[h-screen,overflow-hidden,rounded-none,p-0] t:[&>div]:rounded-drawer"
  [showBranding]="false"
  [showCloseIcon]="false"
  [contentScrollable]="false"
></cg-drawer>
<ng-template #content>
  <cg-olb-options-selection-overlay-search
    class="block h-full"
    [options]="insurances$ | async"
    [labels]="{
      title: 'insuranceType.insuranceSelection.titleAbTest' | transloco,
      searchPlaceholder: 'insuranceType.insuranceSelection.searchPlaceholder' | transloco,
      optionsHeadline: 'insuranceType.insuranceSelection.optionsHeadline' | transloco,
      topOptionsHeadline: 'insuranceType.insuranceSelection.topOptionsHeadline' | transloco,
      noMatchText: 'insuranceType.insuranceSelection.noMatchText' | transloco,
      fuzzyResultHeadline: 'insuranceType.insuranceSelection.fuzzyResultHeadline' | transloco,
      fuzzyEnhancementHeadline: 'insuranceType.insuranceSelection.fuzzyEnhancementHeadline' | transloco,
      footerButtonLabel: 'insuranceType.insuranceSelection.footerButtonLabel' | transloco
    }"
    [close]="close"
  ></cg-olb-options-selection-overlay-search>
</ng-template>
