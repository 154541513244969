import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { YoutubeComponent } from "@cg/youtube";
import { ContentBlockImgComponent, HeadlineComponent, RichtextComponent } from "@cg/core/ui";
import { JsonLdService } from "@cg/json-ld";
import { CtaComponent, ListComponent } from "@cg/shared";
import type {
  ContentBlockImageVideoVertical,
  ContentBlockImageVideoVertical1
} from "@cg/content-api/typescript-interfaces";
import { MediaType } from "../../enums/mediaType.enum";

@Component({
  selector: "cg-content-block-image-video-vertical",
  templateUrl: "./content-block-image-video-vertical.component.html",
  styleUrls: ["./content-block-image-video-vertical.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HeadlineComponent,
    RichtextComponent,
    ListComponent,
    CtaComponent,
    YoutubeComponent,
    ContentBlockImgComponent
  ]
})
export class ContentBlockImageVideoVerticalComponent {
  private _content: ContentBlockImageVideoVertical;

  @Input()
  public set content(value: ContentBlockImageVideoVertical) {
    this._content = value;
    const jsonLdItems = value.items.filter((item: ContentBlockImageVideoVertical1) => !!item.jsonLd);

    jsonLdItems.forEach((item: ContentBlockImageVideoVertical1) =>
      this.jsonLdService.updateJsonLdScriptTag(item.jsonLd)
    );
  }

  public get content(): ContentBlockImageVideoVertical {
    return this._content;
  }

  public mediaType = MediaType;

  public constructor(private jsonLdService: JsonLdService) {}
}
