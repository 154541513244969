import { type Action, createReducer, on } from "@ngrx/store";
import type { MagnoliaResponse } from "../interfaces/magnolia-response.interface";
import * as WebContentActions from "./web-content.actions";

export const WEBCONTENT_FEATURE_KEY = "webContent";

export interface WebContentState {
  currentUrl: string;
  currentContent: MagnoliaResponse;
  loaded: boolean;
  error: string | null;
}

export interface WebContentPartialState {
  readonly [WEBCONTENT_FEATURE_KEY]: WebContentState;
}

export const initialState: WebContentState = {
  currentUrl: null,
  currentContent: null,
  loaded: false,
  error: null
};

const webContentReducer = createReducer(
  initialState,

  on(WebContentActions.setCurrentUrl, (state: WebContentState, { payload }: { payload: string }) => ({
    ...state,
    currentUrl: payload,
    loaded: false
  })),
  on(WebContentActions.getContentNode, (state: WebContentState) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(WebContentActions.getContentNodeSuccess, (state: WebContentState, { payload }: { payload: MagnoliaResponse }) => ({
    ...state,
    currentContent: payload,
    loaded: true,
    error: null
  })),
  on(WebContentActions.getContentNodeFailure, (state: WebContentState, { error }: { error: string }) => ({
    ...state,
    error,
    loaded: true
  }))
);

export function reducer(state: WebContentState | undefined, action: Action) {
  return webContentReducer(state, action);
}
