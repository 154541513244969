import { inject, Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ContactEmailInterface } from "../models/contactEmail.interface";
import * as ContactFormActions from "./contact-form.actions";
import * as ContactFormSelectors from "./contact-form.selectors";

@Injectable()
export class ContactFormFacade {
  private readonly store = inject(Store);

  public loaded$ = this.store.pipe(select(ContactFormSelectors.selectContactFormLoaded));

  public sendEmail(payload: ContactEmailInterface): void {
    this.store.dispatch(ContactFormActions.sendContactEmail({ payload }));
  }
}
