import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent, HeadlineType, RichtextComponent } from "@cg/core/ui";
import type { ContentBlockColumn } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-content-block-column",
  templateUrl: "./content-block-column.component.html",
  styleUrls: ["./content-block-column.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, HeadlineComponent, RichtextComponent]
})
export class ContentBlockColumnComponent {
  @Input()
  public content: ContentBlockColumn;

  public readonly headlineType = HeadlineType;

  public getColumnNumberClass() {
    return "column-wrapper--column-number-" + this.content.columnNumber;
  }
}
