import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import type { YoutubeVideo } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-youtube",
  templateUrl: "./youtube.component.html",
  styleUrls: ["./youtube.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class YoutubeComponent {
  public videoUrl: SafeResourceUrl;

  @Input()
  public set content(value: YoutubeVideo) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${value.videoId}?rel=0&enablejsapi=1`
    );
  }

  public constructor(private sanitizer: DomSanitizer) {}
}
