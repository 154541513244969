import { type Action, createReducer, on } from "@ngrx/store";
import * as ContactFormActions from "./contact-form.actions";

export const CONTACT_FORM_FEATURE_KEY = "contactForm";

export interface ContactFormState {
  loaded: boolean;
  error?: string;
}

export interface ContactFormPartialState {
  readonly [CONTACT_FORM_FEATURE_KEY]: ContactFormState;
}

export const initialContactFormState: ContactFormState = {
  loaded: false
};

const reducer = createReducer(
  initialContactFormState,
  on(ContactFormActions.sendContactEmail, (state: ContactFormState) => ({ ...state, error: null })),
  on(ContactFormActions.sendContactEmailSuccess, (state: ContactFormState) => ({ ...state, loaded: true })),
  on(ContactFormActions.sendContactEmailFailure, (state: ContactFormState, { error }: { error: string }) => ({
    ...state,
    error
  }))
);

export function contactFormReducer(state: ContactFormState | undefined, action: Action) {
  return reducer(state, action);
}
