import { Component, Injectable } from "@angular/core";
import { ComponentMap } from "../interfaces/component-map.interface";
import { componentMap } from "../mappings/content-components.map";

@Injectable({
  providedIn: "root"
})
export class ComponentManagerService {
  public getComponentByTemplateId(templateId: string): Component {
    const result = componentMap.find((item: ComponentMap) => item.templateId === templateId);

    if (!result) {
      console.warn(`Could not find CMS component with template-id ${templateId}`);
      return null;
    }

    return result.component;
  }
}
