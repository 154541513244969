import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "@cg/core/api";
import { EnvironmentConfiguration } from "@cg/core/interfaces";
import { environment } from "@cg/environments";
import { ContactEmailInterface } from "../models/contactEmail.interface";

@Injectable({
  providedIn: "root"
})
export class ContactFormService {
  public config: EnvironmentConfiguration = environment;

  public constructor(private apiService: ApiService) {}

  public sendEmail(payload: ContactEmailInterface): Observable<void> {
    return this.apiService.post("frontendProxy", "/contact/email", payload);
  }
}
