import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineComponent, HeadlineType, PictureComponent } from "@cg/core/ui";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { ListComponent } from "@cg/shared";
import { Trust } from "../../interfaces/trust.interface";
import { trustModel } from "../../models/trust.model";

@Component({
  selector: "cg-trust",
  templateUrl: "./trust.component.html",
  styleUrls: ["./trust.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, PictureComponent, HeadlineComponent, ListComponent]
})
export class TrustComponent implements OnInit {
  protected readonly headlineType = HeadlineType;

  public content: Trust;

  public constructor(@Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean) {}

  public ngOnInit() {
    this.content = trustModel;
  }

  public openInNewTab() {
    if (this.isBrowser) {
      window.open("https://www.tuvsud.com/ms-verbraucherinfo", "_blank");
    }
  }
}
