import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import * as CgValidators from "@cg/validators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { HeadlineComponent, HeadlineType, IconComponent, LinkComponent, ParagraphComponent } from "@cg/core/ui";
import { arrowsIcon, globeIcon, phoneContactIcon } from "@cg/icon";
import { LpnInputComponent, LpnInputForm } from "@cg/lpn-input";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import {
  BaseButtonComponent,
  CccOpeningHoursComponent,
  CtaComponent,
  CtaVariation,
  ErrorMessageComponent,
  FormatTelLinkPipe,
  InputType,
  Lpn,
  OptionSelectionItem,
  OptionsSelectionComponent,
  PhoneNumber,
  TextInputComponent
} from "@cg/shared";
import type { Cta } from "@cg/content-api/typescript-interfaces";
import { ContactFormFacade } from "../+state/contact-form.facade";
import { SubjectType } from "../enums/subjectType.enum";
import { contactInputFields } from "../models/contact-input-fields.model";
import { ContactEmailInterface } from "../models/contactEmail.interface";
import { subjectOptions } from "../models/subject-input.model";

@Component({
  selector: "lib-contact-form",
  standalone: true,
  imports: [
    OlbHeadlineComponent,
    TextInputComponent,
    ReactiveFormsModule,
    LpnInputComponent,
    OptionsSelectionComponent,
    TranslocoPipe,
    BaseButtonComponent,
    CtaComponent,
    LinkComponent,
    ParagraphComponent,
    CccOpeningHoursComponent,
    FormatTelLinkPipe,
    IconComponent,
    MatFormFieldModule,
    MatInputModule,
    HeadlineComponent,
    ErrorMessageComponent
  ],
  templateUrl: "./contact-form.component.html"
})
export class ContactFormComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public form: FormGroup;
  public consentText: string;
  public isLpnInputRequired: boolean;
  public isMobileInputRequired: boolean;

  public readonly cta: Cta = {
    id: "submit-cta",
    title: "Nachricht senden",
    icon: arrowsIcon,
    link: null,
    arrowDirection: "right",
    variation: CtaVariation.PRIMARY,
    ngTemplate: "cgCta"
  };

  public readonly serviceNumber = PhoneNumber.DEFAULT;
  public readonly serviceNumberWorldwide = PhoneNumber.WORLDWIDE;

  protected readonly HeadlineType = HeadlineType;
  protected readonly phoneContactIcon = phoneContactIcon;
  protected readonly globeIcon = globeIcon;
  protected readonly InputType = InputType;
  protected readonly inputFields = contactInputFields;
  protected readonly subjectOptions: OptionSelectionItem[] = subjectOptions;

  public constructor(
    private readonly translocoService: TranslocoService,
    private contactFormFacade: ContactFormFacade
  ) {}

  public ngOnInit(): void {
    this.initForm();
    this.initConsentText();
  }

  public sendEmail(): void {
    this.form.markAllAsTouched();

    if (this.isFormValid()) {
      const controls = this.form.controls;
      const lpn: Lpn = controls.lpn?.value;
      const subject: SubjectType = controls.subject.value;

      const contactEmail: ContactEmailInterface = {
        subject: this.translocoService.translate("contactForm.subjectType.".concat(subject.toString())),
        message: controls.message.value,
        name: controls.firstName?.value,
        surname: controls.lastName.value,
        email: controls.email.value,
        mobileNumber: controls.mobile?.value,
        licensePlate: lpn?.region ? [lpn.region, lpn.letters, lpn.numbers].join(" ") : null
      };

      this.contactFormFacade.sendEmail(contactEmail);
    }
  }

  private isFormValid(): boolean {
    const controls = this.form.controls;
    return (
      this.form.valid ||
      (!controls.lpn.errors &&
        !this.isLpnInputRequired &&
        controls.subject.valid &&
        controls.message.valid &&
        controls.firstName.valid &&
        controls.lastName.valid &&
        controls.email.valid &&
        controls.mobile.valid)
    );
  }

  private initForm(): void {
    this.form = new FormGroup({
      subject: new FormControl<OptionSelectionItem>(null, Validators.required),
      message: new FormControl<string>(null, Validators.required),
      firstName: new FormControl<string>(null, CgValidators.nameValidatorsOptional()),
      lastName: new FormControl<string>(null, CgValidators.nameValidators()),
      email: new FormControl<string>(null, CgValidators.emailValidators(true)),
      mobile: new FormControl<string>(null, CgValidators.phoneValidators(false)),
      lpn: new FormControl<LpnInputForm>(null)
    });

    this.form.controls.subject.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((subject: SubjectType) => {
        this.setLpnValidators(subject);
        this.setMobileValidators(subject);
      });
  }

  private setMobileValidators(subject: SubjectType): void {
    if (this.isMobileRequired(subject)) {
      this.form.controls.mobile.setValidators(CgValidators.phoneValidators(true));
      this.isMobileInputRequired = true;
    } else {
      this.form.controls.mobile.setValidators(CgValidators.phoneValidators(false));
      this.isMobileInputRequired = false;
    }
    this.form.controls.mobile.updateValueAndValidity({ emitEvent: false });
  }

  private isMobileRequired(subject: SubjectType): boolean {
    return SubjectType.CALLBACK === subject;
  }

  private setLpnValidators(subject: SubjectType): void {
    if (this.isLpnRequired(subject)) {
      this.form.controls.lpn.setValidators(Validators.required);
      this.isLpnInputRequired = true;
    } else {
      this.form.controls.lpn.clearValidators();
      this.isLpnInputRequired = false;
    }
    this.form.controls.lpn.updateValueAndValidity({ emitEvent: false });
  }

  private isLpnRequired(subject: SubjectType): boolean {
    return (
      SubjectType.MOVE_APPOINTMENT === subject ||
      SubjectType.CANCEL_APPOINTMENT === subject ||
      SubjectType.BILL_PAYMENT === subject
    );
  }

  private initConsentText(): void {
    this.translocoService
      .selectTranslate("contactForm.privacy", { privacyUrl: "https://www.carglass.de/datenschutz" })
      .subscribe((value: string) => (this.consentText = value));
  }
}
