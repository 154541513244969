import { Inject, Injectable, Optional } from "@angular/core";
import { OnInitEffects } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { REQUEST } from "@cg/core";
import { Request } from "express";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import * as TestbotActions from "./testbot.actions";

@Injectable()
export class TestbotEffects implements OnInitEffects {
  private readonly parameterName = "x-cg-testbot";

  public ngrxOnInitEffects(): Action {
    let identifier: string | null = null;

    // we have to fetch the parameter our selfes because activatedRoute is not ready at this point
    if (!this.isBrowser && this.request && this.parameterName in this.request.query) {
      identifier = this.request.query[this.parameterName] as string;
    } else if (this.isBrowser) {
      const routeParam = window.location.search;
      if (routeParam.includes(this.parameterName)) {
        const regExp = /.*x-cg-testbot=([\d\w-]*)&?/g;
        identifier = regExp.exec(routeParam)[1];
      }
    }

    return TestbotActions.setTestbotHeader({ identifier });
  }

  public constructor(
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
    @Optional() @Inject(REQUEST) private request: Request
  ) {}
}
