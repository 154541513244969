import { Injectable } from "@angular/core";
import { filter, take } from "rxjs/operators";
import { AppointmentFacade, DamageFacade } from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import { CurrentServiceCenter, SharedFacade } from "@cg/carglass-shared-state";
import { InitService } from "@cg/olb/shared";
import { Lpn, Resume } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class CarglassOlbInitService extends InitService {
  public constructor(
    private readonly sharedFacade: SharedFacade,
    private readonly appointmentFacade: AppointmentFacade,
    private readonly damageFacade: DamageFacade,
    private readonly resumeFacade: ResumeFacade
  ) {
    super();
  }

  /**
   * @Todo check sharedFacade -> the subscriptions will be completed before we subscribe
   * because of this the first operator stucks and throws ThrowIfEmpty error
   * CP-18278
   */
  public init(): void {
    this.sharedFacade.resumeResponse$
      .pipe(
        filter((res: Resume) => !!res),
        take(1)
      )
      .subscribe((res: Resume) => this.resumeFacade.setResumeResponse(res));

    this.sharedFacade.currentServiceCenter$
      .pipe(
        filter((sc: CurrentServiceCenter) => !!sc),
        take(1)
      )
      .subscribe((sc: CurrentServiceCenter) => {
        this.appointmentFacade.setFormattedAddress(sc.location);
        this.appointmentFacade.setSelectedServiceCenterIds([sc.costCenter]);
      });

    this.sharedFacade.lpn$
      .pipe(
        filter((lpn: Lpn) => !!lpn?.region),
        take(1)
      )
      .subscribe((lpn: Lpn) => this.damageFacade.setLpn(lpn));
  }
}
