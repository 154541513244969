import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { environment } from "@cg/environments";
import { appConfig } from "./app.config";
import { AppComponent } from "./app/app.component";

if (environment.production) {
  enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  bootstrapApplication(AppComponent, appConfig).catch((err: unknown) => console.error(err));
});
