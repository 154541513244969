import { APP_INITIALIZER, TransferState, makeStateKey } from "@angular/core";
import { ActionReducer, Store } from "@ngrx/store";
import { first } from "rxjs";
import { IS_BROWSER_PLATFORM } from "./utils/src";

export const NGRX_STATE = makeStateKey("NGRX_STATE");
export const SET_ROOT_STATE = "SET_ROOT_STATE";

function drainState(transferState: TransferState, store: Store<any>) {
  // we need to set a placeholder transfer state content
  // otherwise toJson in `serializeTransferStateFactory`
  // of platform-server module is never called because its empty
  transferState.set(NGRX_STATE, "placeholder" as any);

  transferState.onSerialize(NGRX_STATE, () => {
    let state = null;
    store.pipe(first()).subscribe((saveState: any) => {
      state = saveState;
    });
    return state;
  });
}

function rehydrateState(transferState: TransferState, store: Store<any>) {
  const state = transferState.get<any>(NGRX_STATE, null);
  if (state) {
    store.dispatch({ type: SET_ROOT_STATE, payload: state });
  } else {
    console.error("No state rehydration done. Maybe not served with SSR.");
  }
}

export function stateTransferReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {
    if (action.type === SET_ROOT_STATE) {
      return action.payload;
    }
    return reducer(state, action);
  };
}

export function provideServerClientState() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (transferState: TransferState, store: Store<any>, isBrowser: boolean) => async () => {
      if (isBrowser) {
        window["store"] = store;
        rehydrateState(transferState, store);
      } else {
        drainState(transferState, store);
      }
    },
    multi: true,
    deps: [TransferState, Store, IS_BROWSER_PLATFORM]
  };
}
