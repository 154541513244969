<div class="trust-wrapper">
  <div class="icon-wrapper desktop cursor-clickable" (click)="openInNewTab()">
    <cg-picture [content]="content?.picture" [shouldTranslateAlt]="true"></cg-picture>
  </div>
  @if (content) {
    <div class="text-wrapper">
      <cg-headline [type]="headlineType.h1" [content]="content.headline | transloco"></cg-headline>
      <div class="icon-wrapper mobile">
        <cg-picture [content]="content.picture" [shouldTranslateAlt]="true"></cg-picture>
      </div>
      <cg-list [content]="content.list" [shouldTranslateItem]="true" class="pt-4"></cg-list>
    </div>
  }
</div>
