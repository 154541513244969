import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PageComponent } from "@cg/cms";
import { FooterComponent } from "@cg/footer";
import { HeaderComponent } from "@cg/header";

@Component({
  selector: "cg-content-page",
  templateUrl: "./content-page.component.html",
  styleUrls: ["./content-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageComponent, HeaderComponent, FooterComponent]
})
export class ContentPageComponent {}
