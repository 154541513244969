<div class="mx-auto flex flex-col pb-2 t:px-2 d:max-w-[680px] d:px-0" [formGroup]="form">
  <cg-headline [type]="HeadlineType.h3" [content]="'contactForm.subject.title' | transloco"></cg-headline>
  <cg-options-selection
    class="mt-2 [&>div>select]:text-cg-anthracite-light-30"
    [selectionId]="'subject'"
    [formControlName]="'subject'"
    [placeholder]="'contactForm.subject.placeholder' | transloco"
    [translate]="true"
    [items]="subjectOptions"
    [errorMessage]="'contactForm.subject.errorMessage'"
  ></cg-options-selection>
  <cg-headline class="mt-6" [type]="HeadlineType.h4" [content]="'contactForm.message.title' | transloco"></cg-headline>
  <textarea
    class="mt-3 w-full rounded border border-cg-anthracite-light-80 p-2.5 placeholder:text-cg-anthracite-light-80 focus:border-cg-anthracite-light-30 focus:outline-0"
    matInput
    [placeholder]="'contactForm.message.placeholder' | transloco"
    [cdkTextareaAutosize]="true"
    cdkAutosizeMinRows="3"
    cdkAutosizeMaxRows="10"
    formControlName="message"
  ></textarea>
  @if (form.controls.message.touched && form.controls.message.invalid) {
    <cg-error-message [errorMessage]="'contactForm.message.errorMessage' | transloco"></cg-error-message>
  }
  <cg-headline class="mt-6" [type]="HeadlineType.h3" [content]="'contactForm.contact.title' | transloco"></cg-headline>
  <div class="mt-4 grid grid-cols-1 gap-4 d:grid-cols-2">
    <cg-text-input
      autocomplete="given-name"
      formControlName="firstName"
      [content]="inputFields.firstNameInput"
    ></cg-text-input>
    <cg-text-input
      autocomplete="family-name"
      formControlName="lastName"
      [content]="inputFields.lastNameInput"
    ></cg-text-input>
    <cg-text-input
      [content]="inputFields.emailInput"
      [specificInputType]="InputType.E_MAIL"
      formControlName="email"
      data-cy="customer-contact-form-email"
    ></cg-text-input>
    <cg-text-input
      [content]="inputFields.mobileInput"
      [specificInputType]="InputType.NUMBERS_PHONE"
      formControlName="mobile"
      data-cy="customer-contact-form-mobile"
    ></cg-text-input>
  </div>
  <cg-headline
    class="mt-12"
    [type]="HeadlineType.h3"
    [content]="('contactForm.lpn.title' | transloco) + (this.isLpnInputRequired ? '*' : '')"
  ></cg-headline>
  <cg-lpn-input class="mt-4 inline-block [&>div]:!w-full [&>div]:!max-w-[328px]"></cg-lpn-input>
  @if (form.controls.lpn.touched && form.controls.lpn.invalid && this.isLpnInputRequired) {
    <cg-error-message [errorMessage]="'contactForm.lpn.errorMessage' | transloco"></cg-error-message>
  }
  <cg-paragraph
    class="mt-12 part-[text]:x-[!text-[13px],text-sm,text-cg-anthracite-light-30,text-center] [&>p>a]:text-cg-anthracite-light-30"
    [content]="{ text: consentText }"
  >
  </cg-paragraph>
  <cg-cta
    class="mt-6 w-full max-w-[328px] self-center [&>div]:w-full [&>div]:max-w-[328px]"
    [content]="cta"
    (clickedFn)="sendEmail()"
  ></cg-cta>
  <cg-headline
    class="mt-12 d:mt-[120px] d:text-center"
    [type]="HeadlineType.h1"
    [content]="'contactForm.phoneContact.title' | transloco"
  ></cg-headline>
  <p class="mt-4 text-base text-cg-anthracite d:text-center">{{ "contactForm.phoneContact.subtitle" | transloco }}</p>
  <div class="mt-12 grid grid-cols-1 gap-12 d:mt-24 d:grid-cols-2">
    <div class="flex flex-col">
      <div class="flex flex-row gap-8 font-roboto-condensed m:text-lg">
        <cg-icon class="mb-1 h-[72px] w-[72px]" [content]="phoneContactIcon"></cg-icon>
        <div>
          <p class="font-bold text-cg-anthracite">{{ "contactForm.phoneContact.countrywide" | transloco }}</p>
          <a
            class="whitespace-nowrap font-bold text-cg-anthracite underline [word-spacing:-0.075em]"
            [href]="serviceNumber | tel"
            >{{ serviceNumber }}</a
          >
          <cg-ccc-opening-hours
            class="text-start part-[text|>strong]:!font-normal part-[text]:x-[leading-5,text-[13px]]"
          ></cg-ccc-opening-hours>
        </div>
      </div>
      <hr class="mt-6 border border-cg-anthracite d:mt-12" />
    </div>
    <div class="flex flex-col">
      <div class="flex flex-row gap-8 font-roboto-condensed m:text-lg">
        <cg-icon class="mb-1 h-[72px] w-[72px]" [content]="globeIcon"></cg-icon>
        <div>
          <p class="font-bold text-cg-anthracite">{{ "contactForm.phoneContact.worldwide" | transloco }}</p>
          <a
            class="whitespace-nowrap font-bold text-cg-anthracite underline [word-spacing:-0.075em]"
            [href]="serviceNumberWorldwide | tel"
            >{{ serviceNumberWorldwide }}</a
          >
          <p class="pb-1 text-[13px] leading-5 text-cg-anthracite">
            {{ "contactForm.phoneContact.worldwideTeam" | transloco }}
          </p>
        </div>
      </div>
      <hr class="mt-6 border border-cg-anthracite d:mt-12" />
    </div>
  </div>
</div>
