import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { HeadlineComponent } from "@cg/core/ui";
import { BreakpointService, CccPhoneInfoComponent, CtaComponent } from "@cg/shared";
import { TeaserServices } from "../../interfaces/teaser-services.interface";
import { teaserServicesModel } from "../../models/teaser-services.model";
import { TeaserServicesMapComponent } from "../teaser-services-map/teaser-services-map.component";

@Component({
  selector: "cg-teaser-services",
  templateUrl: "./teaser-services.component.html",
  styleUrls: ["./teaser-services.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TeaserServicesMapComponent, HeadlineComponent, CtaComponent, CccPhoneInfoComponent]
})
export class TeaserServicesComponent implements OnInit {
  public destroyRef = inject(DestroyRef);

  public constructor(
    private readonly breakpointService: BreakpointService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public content: TeaserServices;

  public ngOnInit(): void {
    this.content = teaserServicesModel;

    this.breakpointService.isMobile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSmallMobile: boolean) => {
      const hType = isSmallMobile ? "h3" : "h2";
      this.content.leftColumn.headline.type = hType;
      this.content.rightColumn.headline.type = hType;

      this.cdr.markForCheck();
    });
  }
}
