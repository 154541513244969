import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { asyncScheduler, of } from "rxjs";
import { AsyncScheduler } from "rxjs/internal/scheduler/AsyncScheduler";
import { auditTime, catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { OptimizelyService, VariationMap } from "@cg/analytics";
import { errorToString, replaceCopyrightText } from "@cg/core/utils";
import { Content } from "../interfaces/content.interface";
import { MagnoliaResponse } from "../interfaces/magnolia-response.interface";
import { WebContentService } from "../services/web-content.service";
import { getContentNode, getContentNodeFailure, getContentNodeSuccess, setCurrentUrl } from "./web-content.actions";
import { WebContentFacade } from "./web-content.facade";

@Injectable()
export class WebContentEffects {
  public setCurrentUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentUrl),
      map(({ payload }: { payload: string }) => getContentNode({ payload: payload }))
    )
  );

  // redispatch fetching the current content when the variation map changes
  public variationMapChange$ = createEffect(
    () =>
      ({ timeToAudit = 100, scheduler = asyncScheduler }: { timeToAudit?: number; scheduler?: AsyncScheduler } = {}) =>
        this.optimizelyService.variationMap$.pipe(
          filter((v: VariationMap) => Object.keys(v).length > 0),
          auditTime(timeToAudit, scheduler), // we wait for a bunch of variations to prevent multiple requests
          withLatestFrom(this.webContentFacade.currentUrl$),
          filter(([_, url]: [VariationMap, string]) => !!url),
          map(([_, url]: [VariationMap, string]) => getContentNode({ payload: url }))
        )
  );

  public loadWebContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getContentNode),
      withLatestFrom(this.optimizelyService.variationMap$),
      map(([{ payload }, variationMap]: [{ payload: string }, VariationMap]) => ({ payload, variationMap })),
      mergeMap(({ payload, variationMap }: { payload: string; variationMap: VariationMap }) =>
        this.webContentService.getContentNodeFromService(payload, variationMap).pipe(
          map((data: MagnoliaResponse) =>
            getContentNodeSuccess({
              payload: {
                ...data,
                content: replaceCopyrightText<Content[]>(data.content)
              }
            })
          ),
          catchError((error: HttpErrorResponse) => of(getContentNodeFailure({ error: errorToString(error) })))
        )
      )
    )
  );

  public constructor(
    private actions$: Actions,
    private webContentService: WebContentService,
    private webContentFacade: WebContentFacade,
    private optimizelyService: OptimizelyService
  ) {}
}
