<cg-headline [type]="headlineType.h1" [content]="content?.headline"></cg-headline>

<div class="distributor-container">
  @for (card of content.cards; track card) {
    <cg-distributor-card
      class="column is-one-quarter-widescreen is-one-third-desktop is-half-mobile"
      [content]="card"
    ></cg-distributor-card>
  }
</div>
