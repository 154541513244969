<div class="outer-container">
  @for (item of content?.items; track item) {
    <div class="content-block-image-video-container" data-cy="content-block-image-video-container">
      <div class="w-full flex-initial">
        @if (item?.mediaType === mediaType.IMAGE) {
          <cg-content-block-img [content]="item?.picture"></cg-content-block-img>
        } @else {
          <cg-youtube [content]="item?.video"></cg-youtube>
        }
      </div>
      <div class="text-wrapper">
        @if (item?.headline) {
          <cg-headline class="headline-desktop" [type]="'h2'" [content]="item?.headline"></cg-headline>
        }
        <cg-richtext [content]="item?.paragraph"></cg-richtext>
        <cg-list [content]="item?.list"></cg-list>
        @if (item?.ctaLast) {
          <cg-cta class="mr-0 t:mr-4" [content]="item?.ctaLast"></cg-cta>
        }
        @if (item?.ctaFirst) {
          <cg-cta [content]="item?.ctaFirst"></cg-cta>
        }
      </div>
    </div>
  }
</div>
