import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent, HeadlineType } from "@cg/core/ui";
import { ErrorPage } from "../../interfaces/error-page.interface";

@Component({
  selector: "cg-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent]
})
export class ErrorPageComponent {
  @Input()
  public content: ErrorPage;

  public readonly headlineType = HeadlineType;
}
