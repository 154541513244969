import { CockpitComponent } from "@cg/cockpit";
import { ContactFormComponent } from "@cg/contact-form";
import { CostCheckComponent } from "@cg/cost-check";
import { StageHomeComponent, StageSubAlternativeComponent, StageSubComponent } from "@cg/stage";
import { TeaserServicesComponent, TeaserThreeColumnComponent, TeaserTwoColumnComponent } from "@cg/teaser";
import { TrustComponent } from "@cg/trust";
import {
  ContentBlockColumnComponent,
  ContentBlockContactComponent,
  ContentBlockImageVideoHorizontalComponent,
  ContentBlockImageVideoVerticalComponent,
  ContentBlockIntroComponent,
  ContentBlockListComponent
} from "@cg/content-block";
import { H1HeadlineComponent, PictureComponent, RichtextComponent } from "@cg/core/ui";
import { CustomerratingTeaserComponent } from "@cg/customerrating";
import { DistributorCardComponent, DistributorComponent } from "@cg/distributor";
import { CtaComponent, TableComponent } from "@cg/shared";
import type { ComponentMap } from "../interfaces/component-map.interface";

export const componentMap: ComponentMap[] = [
  {
    templateId: "cgStageHome",
    component: StageHomeComponent
  },
  {
    templateId: "cgStageSubpage",
    component: StageSubComponent
  },
  {
    templateId: "cgStageSubpageAlternative",
    component: StageSubAlternativeComponent
  },
  {
    templateId: "cgPicture",
    component: PictureComponent
  },
  {
    templateId: "cgCta",
    component: CtaComponent
  },
  {
    templateId: "cgTeaserTwoColumn",
    component: TeaserTwoColumnComponent
  },
  {
    templateId: "cgTeaserThreeColumn",
    component: TeaserThreeColumnComponent
  },
  {
    templateId: "cgContentBlockColumn",
    component: ContentBlockColumnComponent
  },
  {
    templateId: "cgContentBlockIntro",
    component: ContentBlockIntroComponent
  },
  {
    templateId: "cgContentBlockImageVideoVertical",
    component: ContentBlockImageVideoVerticalComponent
  },
  {
    templateId: "cgContentBlockImageVideoHorizontal",
    component: ContentBlockImageVideoHorizontalComponent
  },
  {
    templateId: "cgContentBlockList",
    component: ContentBlockListComponent
  },
  {
    templateId: "cgContentBlockContact",
    component: ContentBlockContactComponent
  },
  {
    templateId: "cgTeaserServices",
    component: TeaserServicesComponent
  },
  {
    templateId: "cgTable",
    component: TableComponent
  },
  {
    templateId: "cgDistributor",
    component: DistributorComponent
  },
  {
    templateId: "cgDistributorCard",
    component: DistributorCardComponent
  },
  {
    templateId: "cgCustomerRatingsTeaser",
    component: CustomerratingTeaserComponent
  },
  {
    templateId: "cgH1Headline",
    component: H1HeadlineComponent
  },
  {
    templateId: "cgTrust",
    component: TrustComponent
  },
  {
    templateId: "cgRichtext",
    component: RichtextComponent
  },
  {
    templateId: "cgCockpit",
    component: CockpitComponent
  },
  {
    templateId: "cgCostcheck",
    component: CostCheckComponent
  },
  {
    templateId: "cgContact",
    component: ContactFormComponent
  }
];
