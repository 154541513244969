export enum SubjectType {
  MOVE_APPOINTMENT = "move-appointment",
  CANCEL_APPOINTMENT = "cancel-appointment",
  REPAIR_REPLACE = "repair-replace",
  GENERAL = "general",
  COMPLAINT_FEEDBACK = "complaint-feedback",
  VAPS = "vaps",
  TECHNICAL_PROBLEMS = "technical-problems",
  GUARANTEE_WARRANTY = "guarantee-warranty",
  BILL_PAYMENT = "bill-payment",
  CALLBACK = "call-back",
  OTHERS = "others"
}
