import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { IconComponent } from "@cg/core/ui";
import { arrowsIcon } from "@cg/icon";
import { Ctaicon, CtaiconComponent } from "@cg/shared";
import type { DistributorCard, Icon } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-distributor-card",
  templateUrl: "./distributor-card.component.html",
  styleUrls: ["./distributor-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, CtaiconComponent]
})
export class DistributorCardComponent {
  private _content: DistributorCard;

  private _ctaicon: Ctaicon;

  @ViewChild("cta", { read: ElementRef, static: true })
  private _ctaComponent: ElementRef;

  @Input()
  public set content(val: DistributorCard) {
    this._content = val;
    let icon: Icon = this._content.ctaicon.icon;
    if (icon === undefined) {
      icon = arrowsIcon;
    }
    this._ctaicon = { ...val.ctaicon, icon };
  }

  public get content(): DistributorCard {
    return this._content;
  }

  public get ctaicon(): Ctaicon {
    return this._ctaicon;
  }

  public cardClicked() {
    this._ctaComponent.nativeElement.querySelector("a").click();
  }
}
